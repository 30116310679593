import { Link } from 'react-router-dom';

import { urlChecker } from './url-check-if-includes';

import { navigationLinks } from './navigation-links';
import { NewButton } from './sidenav';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  Popover, 
} from '@headlessui/react'
import { useState } from 'react';

export function NavbarNavigation({ page }: { page: string }) {
  const bannerBannedSlugs = ["aimastering", "/feedback-form", "/vocal-presets", "/partners", "/hub", "/education", "/pricing", "/ai-lyrics"];
  const highlightPrimary = !urlChecker(bannerBannedSlugs);


  return (
    <div className="ml-8 space-x-24 h-full pr-36 hidden xxl:block">
      {navigationLinks.map((link) => {
        const isHighlighted = ((link.isPrimary && highlightPrimary) || (!link.isPrimary && urlChecker(link.highlightLinks))) && page !== 'landing';
        const borderClass = isHighlighted ? "border-danger text-white" : "border-transparent hover:border-gray-300";
        
        // State for controlling the visibility of the popover
        const [isPopoverOpen, setIsPopoverOpen] = useState(false);

        return (
          <>
            {link.visibleInNavbar && (
              <div
                key={link.hrefNavbar}
                className="relative inline-block text-left h-full"
                onMouseEnter={() => setIsPopoverOpen(link.content && link.content.length > 0)}
                onMouseLeave={() => setIsPopoverOpen(false)}
              >
                <Link
                    to={link.hrefNavbar}
                    className='h-full'
                  >
                  <div className={`inline-flex h-full items-center border-b-4 text-md font-semibold hover:text-white ${borderClass}`}>
                    <div className="flex gap-x-8 pt-4 items-center overflow-hidden justify-center">
                      <link.icon className='text-white/60 w-20 h-20' />
                      <span className="font-semibold leading-7 line-clamp-1 text-md xl:text-lg">{link.navbarName}</span>
                      {link.isNew && 
                        <NewButton/>
                      }
                    </div>
                  </div>


                </Link>

                {/* Popover menu */}
                {isPopoverOpen && (
                  <div className="absolute left-0 z-50 w-[400px] origin-top-right rounded-md bg-black border-white/20 border-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className='w-full h-full bg-white/10'> 
                      <div className="p-8 space-y-8">
                        {link.content.map((item, idx) => (
                          <Link
                            key={idx}
                            to={item.href}
                            className="block px-4 py-2 text-md font-semibold hover:bg-white/10 rounded-xl"
                          >
                            <div className="flex gap-x-8 p-6 items-center overflow-hidden">
                              <item.icon className='text-dangerdarker w-20 h-20' />
                              <span className="font-semibold leading-7 line-clamp-1 text-lg text-white">{item.name}</span>
                              {item.isNew && 
                                <NewButton/>
                              }
                            </div>
                            <div className='text-gray-400 font-normal text-sm pb-10 px-6'>
                              {item.description}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                
              </div>
            )}
          </>
        );
      })}
    </div>
  );
}

  
  export function NavbarNavigationOld({page} : {page : string}) {
    const bannerBannedSlugs = ["aimastering", "/feedback-form", "/vocal-presets", "/partners", "/hub", "/education", "/pricing"];
    const highlightPrimary = !urlChecker(bannerBannedSlugs);
  
    return (
      <div className="ml-8 space-x-24 h-full ">
        {navigationLinks.map((link) => {
          const isHighlighted = ((link.isPrimary && highlightPrimary) || (!link.isPrimary && urlChecker(link.highlightLinks))) && x !== 'landing';
          const borderClass = isHighlighted ? "border-danger text-white" : "border-transparent hover:border-gray-300";
  
          return (
            <>
            {link.visibleInNavbar && (
              <>
              <Link
                  key={link.hrefNavbar}
                  to={link.hrefNavbar}
                >
                  <div className={`inline-flex h-full items-center border-b-4 text-md font-semibold hover:text-white ${borderClass}`}>
                    <div className="flex gap-x-8 pt-4 items-center overflow-hidden">
                      <link.icon className='text-white/60 w-20 h-20' />
                      <span className="font-semibold leading-7 line-clamp-1 text-lg">{link.navbarName}</span>

                    </div>
                  </div>


                </Link>
              </>
            )}
            </>
          );
        })}    
        </div>
    );
  }
  