import { Track } from '@app/landing-page/tracks/track';
import { Table, TableProps } from '@common/ui/tables/table';
import { ColumnConfig } from '@app/landing-page/com/datatable/column-config';
import { Trans } from '@common/i18n/trans';
import React, { useMemo } from 'react';
import { AlbumLink } from '@app/landing-page/albums/album-link';
import { ScheduleIcon } from '@common/icons/material/Schedule';
import { FormattedDuration } from '@common/i18n/formatted-duration';
import { ArtistLinks } from '@app/landing-page/artists/artist-links';
import { TogglePlaybackColumn } from '@app/landing-page/tracks/track-table/toggle-playback-column';
import { TrackNameColumn } from '@app/landing-page/tracks/track-table/track-name-column';
import { TrackTableMeta } from '@app/landing-page/tracks/track-table/use-track-table-meta';
import { Skeleton } from '@common/ui/skeleton/skeleton';
import { NameWithAvatarPlaceholder } from '@app/landing-page/com/datatable/column-templates/name-with-avatar';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { RowElementProps } from '@common/ui/tables/table-row';
import { TableTrackContextDialog } from '@app/landing-page/tracks/context-dialog/table-track-context-dialog';
import { TrendingUpIcon } from '@common/icons/material/TrendingUp';
import { FormattedRelativeTime } from '@common/i18n/formatted-relative-time';
import { trackToMediaItem } from '@app/landing-page/tracks/utils/track-to-media-item';
import { usePlayerActions } from '@app/landing-page/com/player/hooks/use-player-actions';
import { useIsMobileMediaQuery } from '@common/utils/hooks/is-mobile-media-query';
import { TrackOptionsColumn } from '@app/landing-page/tracks/track-table/track-options-column';
import { TableDataItem } from '@common/ui/tables/types/table-data-item';
import { IconButton } from '@common/ui/buttons/icon-button';
import { DownloadIcon } from '@common/icons/material/Download';
import { downloadFileFromUrl } from '@app/landing-page/com/uploads/utils/download-file-from-url';
import { TrackBpmColumn } from '@app/landing-page/tracks/track-table/track-bpm-column';
import { TrackKeyColumn } from '@app/landing-page/tracks/track-table/track-key-column';
import { GenreLink } from '@app/landing-page/genres/genre-link';
import { Chip } from '@common/ui/forms/input-field/chip-field/chip';
import { Link } from 'react-router-dom';
import { TrackGenresColumn } from '@app/landing-page/tracks/track-table/track-genre-column';
import { TrackTagsColumn } from '@app/landing-page/tracks/track-table/track-tags-column';
import { DownloadAgreement } from '@app/landing-page/com/modals/download-confirmation-modal'; // Update the path accordingly
import { CheckIcon, XMarkIcon, ArrowDownCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'flowbite-react'
import { TrackImage } from '@app/landing-page/tracks/track-image/track-image';

function PricingTooltipd() {
  return (
    <div className='p-10 text-md font-semibold text-white text-center mx-auto'>
      1 credit
    </div>
  )
}

const columnConfig: ColumnConfig<Track>[] = [

  {
    key: 'index',
    header: () => <span>#</span>,
    align: 'center',
    width: 'w-24 flex-shrink-0',
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <Skeleton size="w-20 h-20" variant="rect" />;
      }
      return (
        <div className='overflow-hidden'>
          <TogglePlaybackColumn
            track={track}
            rowIndex={row.index}
            isHovered={row.isHovered}
          />
        </div>
      );
    },
  },
  {
    key: 'name',
    allowsSorting: true,
    width: 'flex-3 min-w-160',
    visibleInMode: 'all',
    header: () => <Trans message="Title" />,
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <NameWithAvatarPlaceholder showDescription={false} />;
      }
      return <TrackNameColumn track={track} />;
    },
  },
  {
    key: 'name_mobile',
    allowsSorting: true,
    width: 'flex-3 min-w-160',
    visibleInMode: 'all',
    header: () => <Trans message="Title" />,
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <NameWithAvatarPlaceholder showDescription={false} />;
      }

      return track.genres && track.genres.length > 0 ?
        <div style={{ marginBottom: '8px' }}>
          <div className='inline-flex'>
            <TrackImage className="rounded w-36 h-36 object-cover mr-10 mt-3" track={track} />
            <div>
              <TrackNameColumn track={track} forceHide={true} />
              <div className='pt-8 pr-100' />

              <div className='inline-flex ml-4 items-stretch'>
                <div className='flex justify-center items-center'>
                  <TrackGenresColumn genres={track.genres} />
                </div>
                <div className='flex justify-center items-center'>
                  <TrackTagsColumn tags={track.tags} />
                </div>
              </div>

            </div>
          </div>
        </div> :
        null; // Or replace null with a default message if desired
    },
  },
  {
    key: 'genres',
    allowsSorting: true,
    header: () => <Trans message="Genres" />,
    width: 'flex-2 max-w-160',
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <Skeleton className="leading-3 max-w-4/5" />;
      }
      return track.genres && track.genres.length > 0 ?
        <TrackGenresColumn genres={track.genres} /> :
        null; // Or replace null with a default message if desired
    },
  },
  {
    key: 'tags',
    header: () => <Trans message="Tags" />,
    width: 'flex-2 max-w-40 lg:max-w-160 hidden xxl:flex',
    body: (track, row) => {
      // console.log('pg', track, row);
      if (row.isPlaceholder) {
        return <Skeleton className="inline-flex leading-3 max-w-4/5" />;
      }
      return (
        <div className='overflow-hidden'>
          <TrackTagsColumn tags={track.tags} />
        </div>
      );
    },
  },
  {
    key: 'album_name',
    allowsSorting: true,
    width: 'flex-2',
    header: () => <Trans message="Pack" />,
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <Skeleton className="leading-3 max-w-4/5" />;
      }
      return track.albums?.[0] ? <AlbumLink album={track.albums[0]} /> : null;
    },
  },

  {
    key: 'bpm',
    allowsSorting: true,
    width: 'flex-2 max-w-40',
    visibleInMode: 'all',
    header: () => <Trans message="BPM" />,
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <NameWithAvatarPlaceholder showDescription={false} />;
      }
      return <TrackBpmColumn bpm={track.bpm} />;
    },
  },
  {
    key: 'key',
    allowsSorting: true,
    width: 'flex-2 max-w-40',
    header: () => <Trans message="Key" />,
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <NameWithAvatarPlaceholder showDescription={false} />;
      }
      return <TrackKeyColumn trackKey={track.key} />;
    },
  },

  {
    key: 'added_at',
    sortingKey: 'likes.created_at',
    allowsSorting: true,
    maxWidth: 'max-w-40',
    header: () => <Trans message="Date added" />,
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <Skeleton className="leading-3 max-w-4/5" />;
      }
      return <FormattedRelativeTime date={track.added_at} />;
    },
  },
  {
    key: 'duration',
    allowsSorting: true,
    className: 'text-muted',
    maxWidth: 'max-w-40',
    // align: 'end',
    header: () => <div className='flex w-full h-full items-center'><ScheduleIcon /></div>,
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <Skeleton className="leading-3" />;
      }
      return track.duration ? <FormattedDuration ms={track.duration} /> : null;
    },
  },
  // {
  //   key: 'popularity',
  //   allowsSorting: true,
  //   className: 'text-muted',
  //   maxWidth: 'max-w-54',
  //   header: () => <TrendingUpIcon />,
  //   body: (track, row) => {
  //     if (row.isPlaceholder) {
  //       return <Skeleton className="leading-3" />;
  //     }
  //     return (
  //       <div className="h-6 w-full relative bg-chip">
  //         <div
  //           style={{ width: `${track.popularity || 50}%` }}
  //           className="h-full w-0 absolute top-0 left-0 bg-black/30 dark:bg-white/30"
  //         />
  //       </div>
  //     );
  //   },
  // },

  
  {
    key: 'options',
    align: 'end',
    width: 'w-36 md:w-80',
    header: () => <Trans message="Options" />,
    hideHeader: true,
    visibleInMode: 'all',
    body: (track, row) => {
      if (row.isPlaceholder) {
        return (
          <div className="flex justify-end">
            <Skeleton size="w-20 h-20" variant="rect" />
          </div>
        );
      }
      return <TrackOptionsColumn track={track} isHovered={row.isHovered} />;
    },
  },
  {
    key: 'download',
    header: () => <Trans message="" />,
    align: 'start',
    maxWidth: 'max-w-40 lg:max-w-84 lg:min-w-84 ',
    visibleInMode: 'all',
    body: (track, row) => {
      if (row.isPlaceholder) {
        return <Skeleton size="w-20 h-20" variant="rect" />;
      }

      // Assuming DownloadAgreement also returns a button or clickable element
      return (
        <div className='flex items-center justify-center '>
          {/**
          <Tooltip content={<PricingTooltipd />} style="dark">
            <DownloadAgreement track={track} />
          </Tooltip>
           */}
          <DownloadAgreement track={track} />

        </div>

      );

      return (
        <Tooltip content={<PricingTooltipd />} style="dark">
          <IconButton
            onClick={async () => {
              const userAgreed = window.confirm("Do you agree with the terms and conditions?");
              if (!userAgreed) return;

              const token = localStorage.getItem('access_token'); // Fetch the token from local storage

              // Make an API call to deduct credits from the user's wallet
              const response = await fetch(`/api/v1/wallet/deduct/${track.id}`, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${token}`, // Use the token here
                },
              });
              // console.log('wallet deduct', response)
              if (response.ok) {
                // If the deduction was successful, then download the WAV file
                if (track.wav_downloads) {
                  downloadFileFromUrl(`/api/v1/tracks/${track.id}/wav_downloads`); // Here we use the correct endpoint to download the WAV file
                } else {
                  alert('WAV file is not available for download.');
                }
              } else {
                // Handle any errors, like insufficient funds
                alert('Could not deduct credits. Please check your wallet.');
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      );
    },
  },

];

export interface TrackTableProps {
  tracks: Track[] | TableDataItem[]; // might be passing in placeholder items for skeletons
  hideArtist?: boolean;
  hideAlbum?: boolean;
  hideTrackImage?: boolean;
  hidePopularity?: boolean;
  hideAddedAtColumn?: boolean;
  hideHeaderRow?: boolean;
  queueGroupId?: string | number;
  renderRowAs?: TableProps<Track>['renderRowAs'];
  sortDescriptor?: TableProps<Track>['sortDescriptor'];
  onSortChange?: TableProps<Track>['onSortChange'];
  enableSorting?: TableProps<Track>['enableSorting'];
  tableBody?: TableProps<Track>['tableBody'];
  className?: string;

}
export function TrackTable({
  tracks,
  hideArtist = false,
  hideAlbum = false,
  hideHeaderRow = false,
  hideTrackImage = false,
  hidePopularity = true,
  hideAddedAtColumn = true,
  queueGroupId,
  renderRowAs,
  ...tableProps
}: TrackTableProps) {
  const player = usePlayerActions();
  const isMobile = useIsMobileMediaQuery();
  hideHeaderRow = hideHeaderRow || !!isMobile;

  const filteredColumns = useMemo(() => {
    return columnConfig.filter(col => {
      if (col.key === 'artist' && (hideArtist || isMobile)) {
        return false;
      }
      if (col.key === 'album_name' && hideAlbum) {
        return false;
      }
      if (col.key === 'popularity' && hidePopularity) {
        return false;
      }
      if (col.key === 'added_at' && hideAddedAtColumn) {
        return false;
      }
      if (col.key === 'genres' && isMobile) {
        return false;
      }
      if (col.key === 'key' && isMobile) {
        return false;
      }
      if (col.key === 'bpm' && isMobile) {
        return false;
      }
      if (col.key === 'name_mobile' && !isMobile) {
        return false;
      }
      if (col.key === 'name' && isMobile) {
        return false;
      }
      return true;
    });
  }, [hideArtist, hideAlbum, hidePopularity, hideAddedAtColumn, isMobile]);


  const meta: TrackTableMeta = useMemo(() => {
    return { queueGroupId: queueGroupId, hideTrackImage };
  }, [queueGroupId, hideTrackImage]);

  return (
    <Table
      closeOnInteractOutside
      hideHeaderRow={hideHeaderRow}
      selectionStyle="highlight"
      selectRowOnContextMenu
      renderRowAs={renderRowAs || TrackTableRowWithContextMenu}
      columns={filteredColumns}
      data={tracks as Track[]}
      meta={meta}
      hideBorder={false}
      cellHeight="auto"
      onAction={(track, index) => {
        const newQueue = tracks.map(d =>
          trackToMediaItem(d as Track, queueGroupId)
        );
        //overrideQueueAndPlay
        player.overrideQueueAndPlay(newQueue, index);
      }}
      {...tableProps}
    />
  );
}

function TrackTableRowWithContextMenu({
  item,
  children,
  ...domProps
}: RowElementProps<Track>) {
  const row = <div {...domProps}>{children}</div>;
  if (item.isPlaceholder) {
    return row;
  }
  return (
    <DialogTrigger type="popover" triggerOnContextMenu placement="bottom-start">
      {row}
      <TableTrackContextDialog />
    </DialogTrigger>
  );
}
